import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IInvitation } from '../types/invitations';

@Injectable({
    providedIn: 'root'
})
export class InvitationService {
    private http = inject(HttpClient);
    private baseUrl: string = 'api/invitations';

    public inviteUser(data: IInvitation): Observable<any> {
        return this.http.post<any>(this.baseUrl, data);
    }
}
